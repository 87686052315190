import React, { useState } from 'react';
import logo from './assets/img/logo.svg';
import salo from './assets/salo.mp4';
import squeel from './assets/sounds/squeel.mp3';
import snort from './assets/sounds/snort.mp3';
import mad from './assets/sounds/mad.mp3';
import deadline from './data/deadline.json';
import countdown from 'countdown';

const sounds = ['squeel', 'snort', 'mad'];

function pluralize(number, one, two, five) {
  let n = Math.abs(number);
  n %= 100;
  if (n >= 5 && n <= 20) {
    return five;
  }
  n %= 10;
  if (n === 1) {
    return one;
  }
  if (n >= 2 && n <= 4) {
    return two;
  }
  return five;
}

function createMessage(counter) {
  let message = 'Спешим попробовать новенькое через ';
  if (counter.days) {
    message += `${ counter.days } ${ pluralize(counter.days, 'день', 'дня', 'дней') }`;
  }
  if (counter.hours) {
    if (counter.days) {
      message += counter.minutes ? ', ': ' и ';
    }
    message += `${ counter.hours } ${ pluralize(counter.hours, 'часик', 'часика', 'часов') }`;
  }
  if (counter.minutes) {
    if (counter.days || counter.hours) {
      message += ' и ';
    }
    message += `${ counter.minutes } ${ pluralize(counter.minutes, 'минуточку', 'минуточки', 'минуточек') }`;
  }
  message += '!';
  return message;
}

function performSqueel() {
  const soundId = sounds[Math.floor(Math.random() * sounds.length)];
  const sound = document.getElementById(soundId);
  sound.play();
}

function App() {
  const [counter, setCounter] = useState(null);
  const { year, month, day, hour, minute } = deadline;
  const deadlineDate = new Date(year, month, day, hour, minute);
  if (year > 0) {
    countdown(
      deadlineDate,
      (changeset) => {
        if (!counter || counter.minutes !== changeset.minutes) {
          setCounter(changeset);
        }
      },
      countdown.DAYS | countdown.HOURS | countdown.MINUTES,
    );
  }
  return (
    <>
      <audio id='squeel' src={ squeel } />
      <audio id='snort' src={ snort } />
      <audio id='mad' src={ mad } />
      <video oncontextmenu={ () => false } autoPlay playsInline muted loop className='salo-background'>
        <source src={ salo } type='video/mp4' />
      </video>
      <div className='polkabana'>
        <img className='logo' src={ logo } alt='Полка Бана' onClick={ performSqueel } />
        {!counter && (
					<p className='countdown'>Пока пауза...</p>
        )}
        {counter && (
          <p className='countdown'>{ createMessage(counter) }</p>
        )}
        <p className='links'>Не забываем чекать наши <a href='https://t.me/polkabana' rel='noopener noreferrer' target='_blank'>тележку</a> и <a href='https://www.instagram.com/polkabana.by/' rel='noopener noreferrer' target='_blank'>инсту</a>.</p>
      </div>
    </>
  );
}

export default App;
